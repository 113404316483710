import { deleteAsync, getAsync, postAsync, putAsync } from "../../core/services";

export interface BudgetProps {
    id: string,
    month: number,
    monthName?: string,
    value: number,
    suggests: SuggestProps[],
    budgetDays: BudgetDayProps[]
}

export interface SuggestProps {
    id: string,
    name: string,
    value: number
}

export interface BudgetDayProps {
    id: string,
    name: string,
    value: number,
    date: string,
    dayOfWeekName: string,
    expenses: BudgetDayExpenseProps[]

}

export interface BudgetDayExpenseProps {
    id: string,
    name: string,
    value: number
}

const UpdateBudgetAsync = async (data : BudgetProps) => {
    return await putAsync(`budgets`, {
        budget: data
    });
}

const GetBudgetAsync = async (id: string) : Promise<BudgetProps> => {
    var response = await getAsync(`budgets/${id}`);
    return response.data.budget;
}

export { GetBudgetAsync, UpdateBudgetAsync }