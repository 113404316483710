import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { AddBudgetDaySchema } from "../../../schemas";
import { Formik, useFormikContext } from "formik";
import GridContainer from "../../../../components/GridContainer";
import GridItem from "../../../../components/GridItem";
import TextInput from "../../../../components/TextInput";
import { DialogProps } from "../../../../core/interfaces";
import { v4 as uuid } from 'uuid';
import { BudgetDayProps } from "../../../api";
import { DatePicker } from "@mui/x-date-pickers";
import DatePickerInput from "../../../../components/DatePickerInput";

export interface AddBudgetDayDialogProps extends DialogProps {
    onAddBudgetDay: (budgetDay: BudgetDayProps) => void;
}
const AddBudgetDayDialog: React.FC<AddBudgetDayDialogProps> = ({ open, onCancel, onAddBudgetDay }) => {
    return (
        <Formik
            initialValues={{
                id: uuid(),
                name: "",
                value: 0,
                expenses: [],
                dayOfWeekName: "",
                date: ""
            }}
            enableReinitialize
            validationSchema={AddBudgetDaySchema}
            onSubmit={(data: BudgetDayProps) => {
                onAddBudgetDay(data);
            }}
        >
            {({ submitForm, getFieldProps, resetForm, status }) => (
                <Dialog open={open}>
                    <DialogTitle>Adicionar Dia</DialogTitle>
                    <DialogContent>
                        <GridContainer mt='20px'>
                            <GridItem >
                                <TextInput
                                    label='Nome'
                                    fieldProps={getFieldProps('name')}
                                />
                            </GridItem>
                            <GridItem mt='10px'>

                                <DatePickerInput
                                    name='date'
                                    fieldProps={getFieldProps('date')}
                                />
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onCancel()}>Cancelar</Button>
                        <Button onClick={() => {
                            submitForm();
                        }}>Ok</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    )
}

export default AddBudgetDayDialog