import { Typography, IconButton, Paper, colors } from "@mui/material"
import moment from "moment"
import GridContainer from "../../../components/GridContainer"
import GridItem from "../../../components/GridItem"
import { BudgetDayProps, SuggestProps } from "../../api"
import {
    Delete as DeleteIcon,
    MoveDown as MoveDownIcon,
    Edit as EditIcon
} from '@mui/icons-material'
import { useState } from "react"
import CopySuggestDialog from "../Dialogs/CopySuggestDialog"
import UpdateBudgetDayDialog from "../Dialogs/UpdateBudgetDayDialog"

interface BudgetDayHeaderProps {
    budgetDay: BudgetDayProps,
    suggests: SuggestProps[],
    onRemoveBudgetDay: (id: string) => void;
    onSuggestCopy: (budgetDayId: string, suggestId: string) => void;
    onUpdateBudgetDay: (budgetDay: BudgetDayProps) => void;
}

const BudgetDayHeader: React.FC<BudgetDayHeaderProps> = ({ budgetDay, suggests, onRemoveBudgetDay, onSuggestCopy, onUpdateBudgetDay }) => {

    const [isCopySuggestOpen, setIsCopySuggestOpen] = useState<boolean>(false);
    const [isUpdateBudgetDayOpen, setIsUpdateBudgetDayOpen] = useState<boolean>(false);

    const internalOnSuggestCopy = (suggestId: string) => {
        onSuggestCopy(budgetDay.id, suggestId);
        setIsCopySuggestOpen(false);
    }

    const internalOnUpdateBudgetDay = (budgetDay: BudgetDayProps) => {
        onUpdateBudgetDay(budgetDay);
        setIsUpdateBudgetDayOpen(false);
    }

    return (
        <>
            <UpdateBudgetDayDialog budgetDay={budgetDay} open={isUpdateBudgetDayOpen} onCancel={() => setIsUpdateBudgetDayOpen(false)} afterSubmit={() => { }} onUpdateBudgetDay={internalOnUpdateBudgetDay} />
            <CopySuggestDialog suggests={suggests} open={isCopySuggestOpen} onCancel={() => setIsCopySuggestOpen(false)} afterSubmit={() => { }} onSuggestCopy={internalOnSuggestCopy} />
            <GridContainer mb='10px' display='flex' alignItems='center' justifyContent='space-between' width='100%'>
                <GridItem xs={8} md={8}>
                    <Typography>{moment.utc(new Date(budgetDay.date)).local().format('DD/MM/yyyy')}</Typography>
                </GridItem>
                <GridItem xs={4} md={4}>
                    <GridContainer display='flex' alignItems='center' justifyContent='space-between'>
                        <GridItem xs={4} display='flex' alignItems='center' justifyContent='center'>
                            <IconButton size='small' onClick={() => setIsCopySuggestOpen(true)}>
                                <MoveDownIcon />
                            </IconButton>
                        </GridItem>
                        <GridItem xs={4} display='flex' alignItems='center' justifyContent='center'>
                            <IconButton size='small' onClick={() => setIsUpdateBudgetDayOpen(true)}>
                                <EditIcon color='warning' />
                            </IconButton>
                        </GridItem>
                        <GridItem xs={4} display='flex' alignItems='center' justifyContent='center'>
                            <IconButton size='small' onClick={() => onRemoveBudgetDay(budgetDay.id)}>
                                <DeleteIcon color="error" />
                            </IconButton>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} md={12}>
                    <Paper sx={{backgroundColor: colors.green[800], width: '100%', display:'flex', alignItems:'center' }}>
                        <GridContainer>
                             <GridItem  xs={12} md={12} lg={12} width='100%' display='flex' justifyContent='center'>
                                <Typography>{budgetDay.dayOfWeekName}</Typography>
                             </GridItem>
                        </GridContainer>
                    </Paper>
                </GridItem>
            </GridContainer>
        </>

    )
}

export default BudgetDayHeader